import React, { Component } from "react";
import { CompanyInfoProps } from "../../Interfaces/Props";
import facebook from "../../Media/Graphics/facebook.png";
import instagram from "../../Media/Graphics/instagram.png";
import linkedIn from "../../Media/Graphics/linkedin.png";
import { Icon } from "@material-ui/core";

import "./Info.scss";

class Info extends Component<CompanyInfoProps> {
  render() {
    return (
      <div id="info">
        <a
          className="info-link"
          id="info-facebookLink"
          href={this.props.facebook}
        >
          <img
            id="info-facebookLogo"
            className="info-socialMediaIcon"
            src={facebook}
            alt="facebook link"
          />
        </a>
        <a
          className="info-link"
          id="info-instagramLink"
          href={this.props.instagram}
        >
          <img
            id="info-instagramLogo"
            className="info-socialMediaIcon"
            src={instagram}
            alt="facebook link"
          />
        </a>
        <a
          className="info-link"
          id="info-linkedInLink"
          href={this.props.linkedIn}
        >
          <img
            id="info-linkedInLogo"
            className="info-socialMediaIcon"
            src={linkedIn}
            alt="linkedIn link"
          />
        </a>
        <div id="info-phone">
          <a id="info-phoneLink" href={`tel:${this.props.phone}`}>
            <Icon className="info-infoIcon">phone</Icon>
            {this.props.phone}
          </a>
        </div>
        <div id="info-email">
          <a id="info-mailLink" href={`mailto:${this.props.email}`}>
            <Icon className="info-infoIcon">email</Icon>
            {this.props.email}
          </a>
        </div>
      </div>
    );
  }
}

export default Info;
