import React, { Component } from "react";
import { NavLink } from "react-router-dom";

import "./Pool.scss";

class Pool extends Component {
  render() {
    return (
      <div id="home-pool">
        <NavLink exact to="/services/pool" className="home-poolLink">
          <div id="home-poolTitle">Now Offering Pool Water Delivery</div>
          <div id="home-poolSubtitle">We can fill it fast!</div>
          <div id="home-poolLearnMore">
            <span id="home-poolLearnUnderline">Click Here To Learn More</span>
          </div>
        </NavLink>
      </div>
    );
  }
}

export default Pool;
