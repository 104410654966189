import React, { Component } from "react";
import { CompanyInfoProps, ImageProps } from "../../../Interfaces/Props";
import ContactForm from "../../ContactForm/Contact";
import contact from "../../../Media/Images/contact.jpg";
import HeaderImage from "../../HeaderImage/HeaderImage";
import OverviewText from "../../OverviewText/OverviewText";
import Helmet from "react-helmet";

import "./Contact.scss";
import Map from "../../Map/Map";
import { Icon } from "@material-ui/core";

class Contact extends Component<CompanyInfoProps> {
  render() {
    const imageProps: ImageProps = {
      title: "Contact Us",
      image: contact
    };
    return (
      <div id="contact">
        <Helmet>
          <title>Contact Us</title>
          <meta
            name="description"
            content="Have a project you think Froman Excavating is right for? Give us a call or send us an email."
          />
        </Helmet>
        <HeaderImage {...imageProps} />
        <OverviewText
          subtitle={"Let’s Get Your Project Started"}
          overview={[
            "If you need an expert’s assistance, we are here to help!",
            "If a service you need is not directly listed on our site, don’t hesitate to contact us; we have the tools and expertise to accommodate almost any request.",
            "Give us a call or send us an email if you think that Froman Excavating is the right team for you."
          ]}
        />
        <div className="contact-methods">
          <div className="contact-method">
            <Icon className="contact-icon">phone</Icon>
            <a id="contact-phoneLink" href={`tel:${this.props.phone}`}>
              {this.props.phone}
            </a>
          </div>

          <div className="contact-method">
            <Icon className="contact-icon">email</Icon>
            <a id="contact-mailLink" href={`mailto:${this.props.email}`}>
              {this.props.email}
            </a>
          </div>

          <div className="contact-method">
            <Icon className="contact-icon">place</Icon>
            {this.props.address}
          </div>
        </div>
        <Map url={this.props.mapURL} />
        <ContactForm email={this.props.email} />
      </div>
    );
  }
}

export default Contact;
