import React, { Component } from "react";
import ContactForm from "../../ContactForm/Contact";
import { HomepageProps } from "../../../Interfaces/Props";
import ImageCarousel from "../../ImageCarousel/ImageCarousel";
import Helmet from "react-helmet";
import OverviewText from "../../OverviewText/OverviewText";
import ServiceLinks from "../../ServiceLinks/ServiceLinks";

import "./Home.scss";
import Pool from "../../Pool/Pool";

class Home extends Component<HomepageProps> {
  render() {
    return (
      <div id="home">
        <Helmet>
          <title>Home</title>
          <meta
            name="description"
            content="Froman Excavation: Specializing Pool Water
    Delivery, Excavation Services, Snow Removal, Concrete Pumping, and Concrete
    Construction. Located in Central Bucks County"
          />
        </Helmet>
        <ImageCarousel images={this.props.images.images} />
        <OverviewText
          subtitle={"Welcome to Froman Excavating!"}
          overview={[
            "At Froman Excavating, we pride ourselves in doing the job right. Our goal is to meet your needs and expectations, then surpass them.",
            "We deal with all things excavating, including pools, foundations, and utility work. We also pour and pump concrete, and remove snow.",
            "Take a look around our site to see what Froman Excavating can do for you!"
          ]}
        />
        <Pool />
        <ServiceLinks title={"Learn More About Our Services"} />
        <ContactForm email={this.props.email.email} />
      </div>
    );
  }
}

export default Home;
