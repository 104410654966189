import React, { Component } from "react";
import {
  ServiceProps,
  CompanyInfoProps,
  CarouselProps,
  NoticeProps
} from "./Interfaces/Props";
import Nav from "./Components/Nav/Nav";
import ScrollToTop from "./Components/Tools/ScrollToTop";
import NoticeBar from "./Components/NoticeBar/NoticeBar";
import { Switch, Route } from "react-router";
import About from "./Components/Pages/About/About";
import Home from "./Components/Pages/Home/Home";
import Construction from "./Components/Pages/Construction/Construction";
import Excavation from "./Components/Pages/Excavation/Excavation";
import Pool from "./Components/Pages/Pool/Pool";
import Pumping from "./Components/Pages/Pumping/Pumping";
import Snow from "./Components/Pages/Snow/Snow";
import Contact from "./Components/Pages/Contact/Contact";
import Footer from "./Components/Footer/Footer";
import Info from "./Components/Info/Info";
import Services from "./Components/Pages/Services/Services";

import "./App.scss";

require("es6-object-assign/auto");

const sanityClient = require("@sanity/client");

class App extends Component {
  state: {
    excavationServices: ServiceProps[];
    constructionServices: ServiceProps[];
    pumpingServices: ServiceProps[];
    snowServices: ServiceProps[];
    poolServices: ServiceProps[];
    companyDetails: CompanyInfoProps[];
    carouselImages: CarouselProps;
    notice: NoticeProps;
    email: string;
  };
  client: any;

  constructor(props: any) {
    super(props);
    this.state = {
      excavationServices: [],
      constructionServices: [],
      pumpingServices: [],
      snowServices: [],
      poolServices: [],
      companyDetails: [],
      carouselImages: { images: [] },
      notice: { notice: false },
      email: ""
    };

    this.client = sanityClient({
      projectId: "n0g1tna9",
      dataset: "website_data",
      useCdn: true
    });

    this.client
      .fetch(
        `*[_type == "excavationServices"]{'list': excavationServicesList[]{title, description[], 'images': images[].asset->url, "video": serviceVideo.asset->url, "caption": serviceVideoCaption, time[], notes}}[0]`
      )
      .then((results: any) => {
        this.setState({
          excavationServices: results.list.map((result: ServiceProps) => ({
            title: result.title,
            description: result.description ? result.description : false,
            images: result.images ? result.images : false,
            video: result.video ? result.video : false,
            caption: result.caption ? result.caption : false,
            time: result.time ? result.time : false,
            notes: result.notes ? result.notes : false
          }))
        });
      });

    this.client
      .fetch(
        `*[_type == "concreteConstructionServices"]{'list': constructionServicesList[]{title, description[], 'images': images[].asset->url, "video": serviceVideo.asset->url, "caption": serviceVideoCaption, time[], notes}}[0]`
      )
      .then((results: any) => {
        this.setState({
          constructionServices: results.list.map((result: ServiceProps) => ({
            title: result.title,
            description: result.description ? result.description : false,
            images: result.images ? result.images : false,
            video: result.video ? result.video : false,
            caption: result.caption ? result.caption : false,
            time: result.time ? result.time : false,
            notes: result.notes ? result.notes : false
          }))
        });
      });

    this.client
      .fetch(
        `*[_type == "concretePumpingServices"]{'list': pumpingServicesList[]{title, description[], 'images': images[].asset->url, "video": serviceVideo.asset->url, "caption": serviceVideoCaption, time[], notes}}[0]`
      )
      .then((results: any) => {
        this.setState({
          pumpingServices: results.list.map((result: ServiceProps) => ({
            title: result.title,
            description: result.description ? result.description : false,
            images: result.images ? result.images : false,
            video: result.video ? result.video : false,
            caption: result.caption ? result.caption : false,
            time: result.time ? result.time : false,
            notes: result.notes ? result.notes : false
          }))
        });
      });

    this.client
      .fetch(
        `*[_type == "snowRemovalServices"]{'list': snowServicesList[]{title, description[], 'images': images[].asset->url, "video": serviceVideo.asset->url, "caption": serviceVideoCaption, time[], notes}}[0]`
      )
      .then((results: any) => {
        this.setState({
          snowServices: results.list.map((result: ServiceProps) => ({
            title: result.title,
            description: result.description ? result.description : false,
            images: result.images ? result.images : false,
            video: result.video ? result.video : false,
            caption: result.caption ? result.caption : false,
            time: result.time ? result.time : false,
            notes: result.notes ? result.notes : false
          }))
        });
      });

    this.client
      .fetch(
        `*[_type == "poolWaterDeliveryServicesList"]{'list': poolServicesList[]{title, description[], 'images': images[].asset->url, "video": serviceVideo.asset->url, "caption": serviceVideoCaption, time[], notes}}[0]`
      )
      .then((results: any) => {
        this.setState({
          poolServices: results.list.map((result: ServiceProps) => ({
            title: result.title,
            description: result.description ? result.description : false,
            images: result.images ? result.images : false,
            video: result.video ? result.video : false,
            caption: result.caption ? result.caption : false,
            time: result.time ? result.time : false,
            notes: result.notes ? result.notes : false
          }))
        });
      });

    this.client
      .fetch(
        `*[_type == 'contactInfo']{'facebook': facebookLink, 'linkedIn': linkedinLink, 'instagram': instagramLink, 'email': emailAddress, 'phone': phoneNumber, "address": location, hours, mapURL}[0]`
      )
      .then((result: CompanyInfoProps) =>
        this.setState({ companyDetails: [result], email: result.email })
      );

    this.client
      .fetch(`*[_type == 'carouselImages']{'images': images[].asset->url}[0]`)
      .then((result: CarouselProps) =>
        this.setState({ carouselImages: { images: result.images } })
      );

    this.client
      .fetch(`*[_type == "headerNotice"]{notice}[0]`)
      .then((result: NoticeProps) =>
        this.setState({ notice: result ? result : false })
      );
  }

  render() {
    return (
      <div className="App">
        <NoticeBar {...this.state.notice}></NoticeBar>
        <Info {...this.state.companyDetails[0]}></Info>
        <Nav {...this.state.companyDetails[0]}></Nav>
        <ScrollToTop>
          <Switch>
            <Route exact path="/about" component={About} />
            <Route
              exact
              path="/contact"
              render={props => (
                <Contact {...props} {...this.state.companyDetails[0]} />
              )}
            />
            <Route exact path="/services" component={Services} />
            <Route
              exact
              path="/services/construction"
              render={props => (
                <Construction
                  {...props}
                  email={this.state.email}
                  services={this.state.constructionServices}
                />
              )}
            />
            <Route
              exact
              path="/services/excavation"
              render={props => (
                <Excavation
                  {...props}
                  email={this.state.email}
                  services={this.state.excavationServices}
                />
              )}
            />
            <Route
              exact
              path="/services/pool"
              render={props => (
                <Pool
                  {...props}
                  email={this.state.email}
                  services={this.state.poolServices}
                />
              )}
            />
            <Route
              exact
              path="/services/pumping"
              render={props => (
                <Pumping
                  {...props}
                  email={this.state.email}
                  services={this.state.pumpingServices}
                />
              )}
            />
            <Route
              exact
              path="/services/snow"
              render={props => (
                <Snow
                  {...props}
                  email={this.state.email}
                  services={this.state.snowServices}
                />
              )}
            />
            <Route
              path="/"
              render={props => (
                <Home
                  {...props}
                  images={this.state.carouselImages}
                  email={{ email: this.state.email }}
                />
              )}
            />
          </Switch>
        </ScrollToTop>
        <Footer {...this.state.companyDetails[0]}></Footer>
      </div>
    );
  }
}

export default App;
