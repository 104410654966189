import React, { Component } from "react";
import "./Separator.scss";
import ScrollAnimation from "react-animate-on-scroll";

class Separator extends Component {
  state: { rendered: boolean };

  constructor(props: any) {
    super(props);
    this.state = { rendered: false };
  }
  render() {
    return (
      <ScrollAnimation
        animateIn="fadeIn"
        animateOut="fadeOut"
        animateOnce={false}
        offset={100}
        duration={0}
        afterAnimatedIn={() => this.setState({ rendered: true })}
        afterAnimatedOut={() => this.setState({ rendered: false })}
      >
        <div id="separatorContainer">
          <div
            className={`${
              this.state.rendered ? "separatorLineGrown" : undefined
            } separatorLine separatorLineDark`}
          />
          <div
            className={`${
              this.state.rendered ? "separatorLineGrown" : undefined
            } separatorLine separatorLineLight`}
          />
          <div
            className={`${
              this.state.rendered ? "separatorLineGrown" : undefined
            } separatorLine separatorLineDark`}
          />
        </div>
      </ScrollAnimation>
    );
  }
}

export default Separator;
