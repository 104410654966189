import './ServiceList.scss';

import React, { Component } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

import { Icon } from '@material-ui/core';

import { ServiceListProps, ServiceProps } from '../../Interfaces/Props';
import Separator from '../Separator/Separator';

class ServiceList extends Component<ServiceListProps> {
  state: {
    selectedImage: (string | undefined)[];
    searchText: string[];
    search: string;
    searched: boolean;
    results: boolean[];
    loaded: boolean;
  };

  constructor(props: ServiceListProps) {
    super(props);
    this.state = {
      selectedImage: [],
      search: "",
      searched: false,
      searchText: [],
      results: [],
      loaded: false
    };
  }

  componentDidMount() {
    this.setState({ loaded: true });
  }

  componentDidUpdate(props: ServiceListProps) {
    if (props.services.length === this.state.selectedImage.length) return;

    this.setState({
      selectedImage: props.services.map((service: ServiceProps) => {
        if (service.images) return service.images[0];
        else return undefined;
      })
    });

    this.setState({
      searchText: props.services.map((service: ServiceProps) => {
        let serviceSearch = { ...service };
        serviceSearch.images = [];
        serviceSearch.video = "";
        return Object.values(serviceSearch)
          .join(" ")
          .toLowerCase();
      })
    });
  }

  search() {
    if (this.state.search.length > 0) {
      this.setState({ searched: true });
      this.setState({
        results: this.state.searchText.map((text: string) =>
          text.includes(this.state.search.toLowerCase()) ? true : false
        )
      });
      let service = document.getElementById("services-search");
      if (service) {
        window.scrollTo({
          top: service.offsetTop - 50,
          left: 0,
          behavior: "smooth"
        });

        // hide the mobile keyboard
        let element = document.getElementById("services-searchBar");
        if (element !== null) {
          // Force keyboard to hide on input field.
          element.setAttribute("readonly", "readonly");
          // Force keyboard to hide on textarea field.
          element.setAttribute("disabled", "true");
          setTimeout(function() {
            if (element !== null) {
              //actually close the keyboard
              element.blur();
              // Remove readonly attribute after keyboard is hidden.
              element.removeAttribute("readonly");
              element.removeAttribute("disabled");
            }
          }, 100);
        }
      }
    }
  }

  enterPress(e: any) {
    if (e.keyCode === 13) this.search();
  }

  render() {
    return (
      <div
        className="services"
        style={{ visibility: this.state.loaded ? "visible" : "hidden" }}
      >
        <div id="services-search">
          <div className="services-searchBarAndIcon">
            <div
              className="services-searchIconContainer"
              onClick={() => this.search()}
            >
              <Icon className="services-searchIcon">search</Icon>
            </div>
            <input
              id="services-searchBar"
              name="services search bar"
              placeholder="Search for a service"
              value={this.state.search}
              onChange={e =>
                this.setState({ search: e.target.value, searched: false })
              }
              onKeyUp={e => this.enterPress(e)}
            />
          </div>
          <div className="services-searchResultCount">
            {this.state.searched
              ? this.state.results.filter((result: boolean) => result).length >
                0
                ? `Displaying ${
                    this.state.results.filter((result: boolean) => result)
                      .length
                  } Result${
                    this.state.results.filter((result: boolean) => result)
                      .length > 1
                      ? "s"
                      : ""
                  }`
                : "No Results"
              : `Displaying ${this.props.services.length} Service${
                  this.props.services.length > 1 ? "s" : ""
                }`}
          </div>
        </div>
        <Separator />
        {this.props.services.map((service: ServiceProps, i: number) => {
          if (
            !this.state.searched ||
            (this.state.searched && this.state.results[i])
          )
            return (
              <div className="services-serviceContainer" key={i}>
                <div className="services-service">
                  <ScrollAnimation
                    className="services-serviceTitleContainer"
                    animateIn="fadeIn"
                    animateOnce={true}
                  >
                    <div className="services-serviceTitle">{service.title}</div>
                  </ScrollAnimation>
                  <div className="services-serviceInfo" key={i}>
                    {service.description && (
                      <ScrollAnimation
                        className="services-serviceDescriptionContainer"
                        animateIn="fadeIn"
                        animateOnce={true}
                      >
                        <div className="services-serviceDescription">
                          {service.description.map(
                            (paragraph: string, j: number) => (
                              <div
                                className="services-serviceDescritpionItem"
                                key={j}
                              >
                                {paragraph}
                              </div>
                            )
                          )}
                        </div>
                      </ScrollAnimation>
                    )}
                    {service.time && (
                      <ScrollAnimation
                        className="services-serviceSeasonsContainer"
                        animateIn="fadeIn"
                        animateOnce={true}
                      >
                        <div className="services-serviceSeasons">
                          {"Availability: "}
                          {service.time.map((season: string, j: number) => (
                            <span key={j} className="services-serviceSeason">
                              {season}
                              {`${
                                service &&
                                service.time &&
                                j !== service.time.length - 1
                                  ? ","
                                  : ""
                              }`}
                            </span>
                          ))}
                        </div>
                      </ScrollAnimation>
                    )}
                    {service.notes && (
                      <ScrollAnimation
                        className="services-serviceNotesContainer"
                        animateIn="fadeIn"
                        animateOnce={true}
                      >
                        <div className="services-serviceNotes">
                          {service.notes}
                        </div>
                      </ScrollAnimation>
                    )}
                  </div>

                  {(service.images || service.video) && (
                    <div className="services-serviceMedia">
                      {service.video && (
                        <ScrollAnimation
                          className={`${
                            !service.images
                              ? "services-videoOnly"
                              : "services-videoWithImages"
                          } services-videoContainer`}
                          animateIn="fadeIn"
                          animateOnce={true}
                        >
                          <video className="services-video" controls>
                            <source src={service.video} type="video/mp4" />
                            <source src={service.video} type="video/ogg" />
                            <source src={service.video} type="video/webm" />
                          </video>
                          {service.caption && (
                            <div className="services-videoCaption">
                              {service.caption}
                            </div>
                          )}
                        </ScrollAnimation>
                      )}
                      {service.images && (
                        <ScrollAnimation
                          className={`${
                            !service.video
                              ? "services-imagesOnly"
                              : "services-imagesWithVideo"
                          } services-serviceImagePaneContainer`}
                          animateIn="fadeIn"
                          animateOnce={true}
                        >
                          <div
                            className="services-serviceImagePane"
                            style={{
                              backgroundImage: `url(${
                                this.state.selectedImage[i]
                                  ? this.state.selectedImage[i]
                                  : service.images[1]
                              })`
                            }}
                          />
                          {service.images.length > 1 && (
                            <div className="services-imageSelectionPane">
                              {service.images.map(
                                (image: string, j: number) => (
                                  <div
                                    key={j}
                                    className={`${
                                      image === this.state.selectedImage[i]
                                        ? "services-imageTileSelected"
                                        : "services-imageTileUnselected"
                                    } services-imageSelectionTile`}
                                    style={{ backgroundImage: `url(${image})` }}
                                    onClick={() => {
                                      let newSelection = this.state
                                        .selectedImage;
                                      newSelection[i] = image;
                                      this.setState({
                                        selectedImage: newSelection
                                      });
                                    }}
                                  />
                                )
                              )}
                            </div>
                          )}
                        </ScrollAnimation>
                      )}
                    </div>
                  )}
                </div>
                {i !== this.props.services.length - 1 && <Separator />}
              </div>
            );
          else return "";
        })}
      </div>
    );
  }
}

export default ServiceList;
