import React, { Component } from "react";
import { OverviewTextProps } from "../../Interfaces/Props";

import "./OverviewText.scss";

class OverviewText extends Component<OverviewTextProps> {
  render() {
    return (
      <div className="overview">
        <div className="overview-title animated fadeIn">
          {this.props.subtitle}
        </div>
        <div className="animated fadeInUp">
          {this.props.overview.map((text: string | JSX.Element, i: number) => (
            <div className="overview-paragraph" key={i}>
              {text}
            </div>
          ))}
          {this.props.list !== undefined && (
            <div className="overview-listContainer">
              <ul className="overview-list">
                {this.props.list.map((item: string, i: number) => {
                  return <li className="overview-listItem">{item}</li>;
                })}
              </ul>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default OverviewText;
