import React, { Component } from "react";
import { ImageProps, ServiceListProps } from "../../../Interfaces/Props";
import pumping from "../../../Media/Images/pumping.jpg";
import HeaderImage from "../../HeaderImage/HeaderImage";
import OverviewText from "../../OverviewText/OverviewText";
import Helmet from "react-helmet";
import ServiceList from "../../ServiceList/ServiceList";
import Contact from "../../ContactForm/Contact";

import "./Pumping.scss";

class Pumping extends Component<ServiceListProps> {
  render() {
    const imageProps: ImageProps = {
      title: "Concrete Pumping",
      image: pumping
    };
    return (
      <div id="pumping">
        <Helmet>
          <title>Concrete Pumping</title>
          <meta
            name="description"
            content="Our concrete pumps can reach just about anywhere, allowing us to complete difficult concrete projects"
          />
        </Helmet>
        <HeaderImage {...imageProps} />
        <OverviewText
          subtitle={"First Class Operators"}
          overview={[
            "Nothing is out of reach for our concrete pumps. We offer concrete pumping services from trailer pumps up to 47 meter boom pumps.",
            "Give us a call if your project requires precision concrete pumping where traditional trucks can’t access."
          ]}
        />
        <ServiceList email={this.props.email} services={this.props.services} />
        <Contact email={this.props.email} />
      </div>
    );
  }
}

export default Pumping;
