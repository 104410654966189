import React, { Component } from "react";
import { ImageProps, ServiceListProps } from "../../../Interfaces/Props";
import construction from "../../../Media/Images/construction.jpg";
import HeaderImage from "../../HeaderImage/HeaderImage";
import OverviewText from "../../OverviewText/OverviewText";
import Helmet from "react-helmet";
import ServiceList from "../../ServiceList/ServiceList";
import Contact from "../../ContactForm/Contact";

import "./Construction.scss";

class Construction extends Component<ServiceListProps> {
  render() {
    const imageProps: ImageProps = {
      title: "Concrete Construction",
      image: construction
    };
    return (
      <div id="construction">
        <Helmet>
          <title>Concrete Conctruction</title>
          <meta
            name="description"
            content="Let the concrete professionals do your next construction project!"
          />
        </Helmet>
        <HeaderImage {...imageProps} />
        <OverviewText
          subtitle={"First-Class Construction"}
          overview={[
            "Your work-site has been excavated, now it’s time for concrete finishing! We can pour concrete for stormwater systems, patterned retaining walls, foundations, curbs, sidewalks, pools, and more.",
            "If a concrete service you need is not listed, reach out to us to see if we can help. Chances are, if your project requires concrete, we can make it happen."
          ]}
        />
        <ServiceList services={this.props.services} email={this.props.email} />
        <Contact email={this.props.email} />
      </div>
    );
  }
}

export default Construction;
