import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import React from "react";
import App from "./App/App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import "animate.css/animate.min.css";
import { render } from "react-snapshot";
import "./index.scss";

const rootElement = document.getElementById("root");
render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  rootElement
);

serviceWorker.unregister();
