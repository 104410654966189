import React, { Component } from "react";
import { ImageProps, ServiceListProps } from "../../../Interfaces/Props";
import HeaderImage from "../../HeaderImage/HeaderImage";
import excavation from "../../../Media/Images/excavation.jpg";
import OverviewText from "../../OverviewText/OverviewText";
import Helmet from "react-helmet";
import ServiceList from "../../ServiceList/ServiceList";
import Contact from "../../ContactForm/Contact";

import "./Excavation.scss";

class Excavation extends Component<ServiceListProps> {
  render() {
    const imageProps: ImageProps = {
      title: "Excavation Services",
      image: excavation
    };
    return (
      <div id="excavation">
        <Helmet>
          <title>Excavation</title>
          <meta
            name="description"
            content="We're called Froman Excavating for a reason. Let the experts handle your next excavation project"
          />
        </Helmet>
        <HeaderImage {...imageProps} />
        <OverviewText
          subtitle={"Expert Excavation"}
          overview={[
            "Do you have a site that needs excavating? We’ve got you covered; we’re called “Froman Excavation” for a reason!",
            "Our expertise in excavation has been proven repeatedly; we will provide you with the service you deserve, no matter the project.",
            <strong>We specialize in the following excavation projects:</strong>
          ]}
          list={[
            "Swimming Pools",
            "Utility Lines",
            "Parking Lots",
            "Stormwater Systems",
            "Foundations",
            "Retaining Walls",
            "Concrete Crushing",
            "Concrete Recycling",
            "Topsoil Delivery",
            "Rock Hammer Service",
            "Rock Removal Services",
            "Building Demolition"
          ]}
        />
        <ServiceList services={this.props.services} email={this.props.email} />
        <Contact email={this.props.email} />
      </div>
    );
  }
}

export default Excavation;
