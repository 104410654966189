import './Footer.scss';

import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

import { CompanyInfoProps } from '../../Interfaces/Props';
import facebook from '../../Media/Graphics/facebookfooter.png';
import logoSmall from '../../Media/Graphics/footerLogo.png';
import instagram from '../../Media/Graphics/instagramfooter.png';
import linkedIn from '../../Media/Graphics/linkedinfooter.png';
import Bam from '../bam/Bam';
import { isActive } from '../Tools/LinkTools';

class Footer extends Component<CompanyInfoProps> {
  render() {
    let date = new Date();
    const year = date.getFullYear();

    return (
      <div id="footer">
        <div id="footer-logoContainer">
          <NavLink id="footer-logoLink" to="/" exact={true}>
            <img
              id="footer-logo"
              src={logoSmall}
              alt="Froman Excavating Logo"
            />
          </NavLink>
        </div>
        <div id="footer-links">
          <NavLink
            className="footer-link"
            exact={true}
            to="/services/excavation"
            activeClassName="footer-link-active"
            isActive={isActive.bind(this, "/services/excavation")}
          >
            {`EXCAVATION\nSERVICES`}
          </NavLink>
          <NavLink
            className="footer-link"
            exact={true}
            to="/services/construction"
            activeClassName="footer-link-active"
            isActive={isActive.bind(this, "/services/construction")}
          >
            {`CONCRETE\nCONSTRUCTION`}
          </NavLink>
          <NavLink
            className="footer-link"
            exact={true}
            to="/services/pumping"
            activeClassName="footer-link-active"
            isActive={isActive.bind(this, "/services/pumping")}
          >
            {`CONCRETE\nPUMPING`}
          </NavLink>
          <NavLink
            className="footer-link"
            exact={true}
            to="/services/pool"
            activeClassName="footer-link-active"
            isActive={isActive.bind(this, "/services/pool")}
          >
            {`POOL WATER\nDELIVERY`}
          </NavLink>
          <NavLink
            className="footer-link"
            exact={true}
            to="/services/snow"
            activeClassName="footer-link-active"
            isActive={isActive.bind(this, "/services/snow")}
          >
            {`SNOW\nREMOVAL`}
          </NavLink>
        </div>
        <div id="footer-info">
          <div id="footer-socialIcons">
            <div className="footer-socialIconContainer">
              <a className="footer-socialLink" href={this.props.facebook}>
                <img
                  src={facebook}
                  className="footer-socialIcon"
                  alt="Froman Facebook Icon"
                />
              </a>
            </div>

            <div className="footer-socialIconContainer">
              <a className="footer-socialLink" href={this.props.instagram}>
                <img
                  src={instagram}
                  className="footer-socialIcon"
                  alt="Froman Facebook Icon"
                />
              </a>
            </div>
            <div className="footer-socialIconContainer">
              <a className="footer-socialLink" href={this.props.linkedIn}>
                <img
                  src={linkedIn}
                  className="footer-socialIcon"
                  alt="Froman LinkedIn Icon"
                />
              </a>
            </div>
          </div>
          <div id="footer-copy">&copy; {year} Froman Excavating</div>
          <div id="footer-built">
            <Bam />
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
