import React, { Component } from "react";
import { ImageProps } from "../../../Interfaces/Props";
import about from "../../../Media/Images/about.jpg";
import HeaderImage from "../../HeaderImage/HeaderImage";
import Helmet from "react-helmet";
import OverviewText from "../../OverviewText/OverviewText";

import "./About.scss";
import ServiceLinks from "../../ServiceLinks/ServiceLinks";

class About extends Component {
  render() {
    const imageProps: ImageProps = {
      title: "About Us",
      image: about
    };
    return (
      <div id="about">
        <Helmet>
          <title>About Us </title>
          <meta
            name="description"
            content="Learn all about Froman Excavating and the services we provide"
          />
        </Helmet>
        <HeaderImage {...imageProps} />
        <OverviewText
          subtitle={"Getting it right since ’93"}
          overview={[
            "Founded in 1993, Froman Excavating has been committed to getting the job right from the start, no matter the project. We started doing small trench-work and pools with just two employees, and have since expanded to over 35 employees and cover a wide range of excavating needs.",
            "Over time, we have increased our capabilities to include residential and commercial foundations, stormwater management, and paving. We are also experts in concrete placement and pumping.",
            "Whether you need a small swimming pool or a large concrete foundation, we will accommodate your needs."
          ]}
        />
        <ServiceLinks title={"Learn More About Our Services"} />
      </div>
    );
  }
}

export default About;
