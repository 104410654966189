import React, { Component } from "react";
import { ImageProps, ServiceListProps } from "../../../Interfaces/Props";
import pool from "../../../Media/Images/pool.jpg";
import HeaderImage from "../../HeaderImage/HeaderImage";
import OverviewText from "../../OverviewText/OverviewText";
import Helmet from "react-helmet";
import ServiceList from "../../ServiceList/ServiceList";
import Contact from "../../ContactForm/Contact";

import "./Pool.scss";

class Pool extends Component<ServiceListProps> {
  render() {
    const imageProps: ImageProps = {
      title: "Pool Water Delivery",
      image: pool
    };
    return (
      <div id="pool">
        <Helmet>
          <title>Pool Water Delivery</title>
          <meta
            name="description"
            content="Jump-start your summer with pool water delivery from Froman Excavating. We can fill your pool fast!"
          />
        </Helmet>
        <HeaderImage {...imageProps} />
        <OverviewText
          subtitle={"We Can Fill It Fast!"}
          overview={[
            "Jump-start your swimming season with pool water delivery from Froman Excavating!",
            "Our extensive residential and commercial delivery network allows us to deliver water anywhere in the tri-state area at any time that works for you. We offer a prompt delivery 7 days a week."
          ]}
        />
        <ServiceList email={this.props.email} services={this.props.services} />
        <Contact email={this.props.email} />
      </div>
    );
  }
}

export default Pool;
