import React, { Component } from "react";
import { ImageProps, ServiceListProps } from "../../../Interfaces/Props";
import snow from "../../../Media/Images/snow.jpg";
import OverviewText from "../../OverviewText/OverviewText";
import HeaderImage from "../../HeaderImage/HeaderImage";
import ServiceList from "../../ServiceList/ServiceList";
import Helmet from "react-helmet";
import Contact from "../../ContactForm/Contact";

import "./Snow.scss";

class Snow extends Component<ServiceListProps> {
  render() {
    const imageProps: ImageProps = {
      title: "Snow Removal",
      image: snow
    };
    return (
      <div id="snow">
        <Helmet>
          <title>Snow Removal</title>
          <meta
            name="description"
            content="Let us rid your parking lot or driveway of annoying piles of snow buildup"
          />
        </Helmet>
        <HeaderImage {...imageProps} />
        <OverviewText
          subtitle={"Reclaim Your Parking Lot"}
          overview={[
            "We can plow, haul, and dispose of snow in any size lot.",
            "We’ll remove snow anywhere in the tri-state area!"
          ]}
        />
        <ServiceList email={this.props.email} services={this.props.services} />
        <Contact email={this.props.email} />
      </div>
    );
  }
}

export default Snow;
