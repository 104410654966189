import React, { Component } from "react";
import * as emailjs from "emailjs-com";
import { Icon } from "@material-ui/core";
import ScrollAnimation from "react-animate-on-scroll";
import { ContactProps } from "../../Interfaces/Props";

import "./Contact.scss";

class Contact extends Component<ContactProps> {
  state: {
    name: string;
    email: string;
    subject: string;
    message: string;
    sending: boolean;
    highlightInvalid: boolean;
    messageSent: boolean;
    error: boolean;
  };

  constructor(props: any) {
    super(props);
    this.state = {
      name: "",
      email: "",
      subject: "",
      message: "",
      sending: false,
      highlightInvalid: false,
      messageSent: false,
      error: false
    };
    emailjs.init("user_roCVt3s66ByQIsdHc3cTv");
  }

  sendMessage() {
    if (
      this.state.name === "" ||
      this.state.email === "" ||
      this.state.subject === ""
    ) {
      this.setState({ error: false, highlightInvalid: true });
      return;
    }

    this.setState({ error: false, sending: true });

    const service_id = "mailgun";
    const template_id = "froman_template";
    const template_params = {
      receiver: "fromanexcavating@gmail.com",
      name: this.state.name,
      subject: this.state.subject,
      email: this.state.email,
      message: this.state.message
    };

    emailjs
      .send(service_id, template_id, template_params)
      .then((response: any) =>
        this.setState({ messageSent: true, sending: false })
      )
      .catch((error: any) => this.setState({ error: true, sending: false }));
  }

  render() {
    return (
      <div id="contactContainer">
        <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
          <div id="contactHeader">CONTACT US</div>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
          <div id="contactSubtext">
            To learn more about what Froman Excavating can do for you, send us a
            message with the form below.
            {
              <>
                <br />
                <br />
              </>
            }
            You can also contact us through an email client of your choice at
            {<span id="breakPreventer"> {this.props.email}</span>}
          </div>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
          <div id="formContainer">
            <form id="contactForm">
              <input
                className={`formInput ${
                  this.state.messageSent ? "formInputSent " : undefined
                } ${
                  this.state.highlightInvalid && this.state.name === ""
                    ? "invalid"
                    : undefined
                } `}
                id="formName"
                type="text"
                name="name"
                placeholder="Your name"
                value={this.state.name}
                onChange={e => this.setState({ name: e.target.value })}
                disabled={this.state.messageSent}
              />
              <input
                className={`formInput ${
                  this.state.messageSent ? "formInputSent " : undefined
                } ${
                  this.state.highlightInvalid && this.state.email === ""
                    ? "invalid"
                    : undefined
                } `}
                id="formEmail"
                type="text"
                name="email"
                placeholder="Your e-mail"
                value={this.state.email}
                onChange={e => this.setState({ email: e.target.value })}
                disabled={this.state.messageSent}
              />
              <input
                className={`formInput ${
                  this.state.messageSent ? "formInputSent " : undefined
                } ${
                  this.state.highlightInvalid && this.state.subject === ""
                    ? "invalid"
                    : undefined
                } `}
                id="formSubject"
                type="text"
                name="subject"
                placeholder="Subject"
                value={this.state.subject}
                onChange={e => this.setState({ subject: e.target.value })}
                disabled={this.state.messageSent}
              />
              <textarea
                className={`formInput ${
                  this.state.messageSent ? "formInputSent" : undefined
                }`}
                id="formMessage"
                name="message"
                placeholder="Message"
                value={this.state.message}
                onChange={e => this.setState({ message: e.target.value })}
                disabled={this.state.messageSent}
              />
              <div id="submitContainer">
                <div id="submitButton" onClick={this.sendMessage.bind(this)}>
                  {this.state.messageSent ? "SENT" : "SEND"}
                </div>
                <Icon
                  className={`sendingIcon ${
                    this.state.sending
                      ? "sendingIconShown"
                      : "sendingIconHidden"
                  }`}
                >
                  autorenew
                </Icon>
              </div>

              <div id="errorMessage">
                {this.state.error &&
                  "Something went wrong, please try again or use your own email client."}
              </div>
            </form>
          </div>
        </ScrollAnimation>
      </div>
    );
  }
}

export default Contact;
