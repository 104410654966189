import React, { Component } from "react";
import { CompanyInfoProps } from "../../Interfaces/Props";
import { Icon } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import { isActive, stopClickPropagation } from "../Tools/LinkTools";
import { SlideDown } from "react-slidedown";
import logo from "../../Media/Graphics/navLogo.png";

import "./Nav.scss";

class Nav extends Component<CompanyInfoProps> {
  state: {
    showStickyNav: boolean;
    topMobileLinksClosed: boolean;
    stickyMobileLinksClosed: boolean;
    topServicesClosed: boolean;
    stickyServicesClosed: boolean;
    loaded: boolean;
  };

  constructor(props: CompanyInfoProps) {
    super(props);
    this.state = {
      showStickyNav: false,
      topMobileLinksClosed: true,
      stickyMobileLinksClosed: true,
      topServicesClosed: true,
      stickyServicesClosed: true,
      loaded: false
    };
  }

  componentDidMount() {
    this.setState({
      loaded: true
    });
    if (window) {
      window.addEventListener("scroll", e => this.checkScrollPosition());
      window.addEventListener("resize", e =>
        this.setState({
          showTopMobileLinks: false,
          showStickyMobileLinks: false,
          stickyMobileLinksClosed: true,
          topMobileLinksClosed: true,
          topServicesClosed: true,
          stickyServicesClosed: true
        })
      );
    }
  }

  checkScrollPosition() {
    if (window) {
      const scrollPos = window.pageYOffset;
      const nav = document.getElementById("nav-sticky");
      if (!nav) return;
      if (scrollPos - nav.clientHeight > 150) {
        this.setState({ showStickyNav: true });
      } else {
        this.setState({ showStickyNav: false, stickyMobileLinksClosed: true });
      }
    }
  }

  render() {
    return (
      <div
        id="nav"
        style={{ visibility: this.state.loaded ? "visible" : "hidden" }}
      >
        <div id="nav-top">
          <div id="nav-topLogoContainer">
            <NavLink exact={true} to="/" id="nav-topLogoHomeLink">
              <img id="nav-topLogo" src={logo} alt="Froman Excavating Logo" />
            </NavLink>
            <div id="nav-topMenuContainer" className="nav-menuContainer">
              <Icon
                id="nav-topMenuIcon"
                className="nav-menuIcon"
                onClick={() =>
                  this.setState({
                    topMobileLinksClosed: !this.state.topMobileLinksClosed
                  })
                }
              >
                menu
              </Icon>
            </div>
          </div>

          {/*Render both a desktop and a mobile nav, 
			   use css to hide the unused one*/}
          <div id="nav-topDesktopLinksContainer">{this.navLinks(true)}</div>
          <SlideDown
            className="nav-linksSlideDown nav-topLinksSlideDown"
            closed={this.state.topMobileLinksClosed}
            transitionOnAppear={false}
          >
            {this.navLinks(true)}
          </SlideDown>
        </div>

        <div
          id="nav-sticky"
          className={`${
            this.state.showStickyNav ? "nav-stickyNavigationShown" : undefined
          }`}
        >
          <div id="nav-stickyLogoContainer">
            <NavLink exact={true} to="/" id="nav-stickyLogoHomeLink">
              <img
                id="nav-stickyLogo"
                src={logo}
                alt="Froman Excavating Logo"
              />
            </NavLink>
            <div id="nav-stickyMenuContainer" className="nav-menuContainer">
              <Icon
                id="nav-stickyMenuIcon"
                className="nav-menuIcon"
                onClick={() =>
                  this.setState({
                    stickyMobileLinksClosed: !this.state.stickyMobileLinksClosed
                  })
                }
              >
                menu
              </Icon>
            </div>
          </div>

          {/*Render both a desktop and a mobile nav, 
			   use css to hide the unused one*/}
          <div id="nav-stickyDesktopLinksContainer">{this.navLinks(false)}</div>
          <SlideDown
            className="nav-linksSlideDown nav-stickyLinksSlideDown"
            closed={this.state.stickyMobileLinksClosed}
            transitionOnAppear={false}
          >
            {this.navLinks(false)}
          </SlideDown>
        </div>
      </div>
    );
  }

  navLinks(top: boolean) {
    return (
      <div
        className={`nav-links ${
          this.state.stickyMobileLinksClosed
            ? "nav-mobileLinksHidden"
            : "nav-mobileLinksShown"
        }`}
        onClick={() =>
          this.setState({
            stickyMobileLinksClosed: true,
            topMobileLinksClosed: true,
            topServicesClosed: true,
            stickyServicesClosed: true
          })
        }
      >
        <div className="nav-linkContainer">
          <NavLink
            exact={true}
            to="/"
            className="nav-link"
            activeClassName="nav-link-active"
            isActive={isActive.bind(this, "/")}
          >
            <span className="nav-linkText">HOME</span>
          </NavLink>
        </div>
        <div className="nav-linkContainer">
          <NavLink
            exact={true}
            to="/about"
            className="nav-link"
            activeClassName="nav-link-active"
            isActive={isActive.bind(this, "/about")}
          >
            <span className="nav-linkText">ABOUT US</span>
          </NavLink>
        </div>
        <div
          className="nav-linkContainer"
          onMouseEnter={e => {
            stopClickPropagation(e);
            let links = document.getElementById(
              top ? "nav-serviceLinksTop" : "nav-serviceLinksSticky"
            );

            if (links) {
              links.classList.remove("nav-hidden");
            }
          }}
        >
          <NavLink
            exact={true}
            to="/services"
            className="nav-link"
            activeClassName="nav-link-active"
            isActive={isActive.bind(this, "/services")}
          >
            <span className="nav-linkText">OUR SERVICES</span>
            <div
              className="nav-expandServicesIconContainer"
              onClick={e => {
                stopClickPropagation(e);
                top
                  ? this.setState({
                      topServicesClosed: !this.state.topServicesClosed
                    })
                  : this.setState({
                      stickyServicesClosed: !this.state.stickyServicesClosed
                    });
              }}
            >
              <Icon className="nav-expandServicesIcon">
                {top
                  ? this.state.topServicesClosed
                    ? "expand_more"
                    : "expand_less"
                  : this.state.stickyServicesClosed
                  ? "expand_more"
                  : "expand_less"}
              </Icon>
            </div>
          </NavLink>
          <SlideDown
            className="nav-servicesSlideDown"
            closed={
              top
                ? this.state.topServicesClosed
                : this.state.stickyServicesClosed
            }
            transitionOnAppear={true}
          >
            <div
              id={`${top ? "nav-serviceLinksTop" : "nav-serviceLinksSticky"}`}
              className="nav-serviceLinks"
              onClick={() => {
                let links = document.getElementById(
                  top ? "nav-serviceLinksTop" : "nav-serviceLinksSticky"
                );
                if (links) links.classList.add("nav-hidden");
                this.setState({
                  showServicesSticky: false,
                  showServicesTop: false
                });
              }}
            >
              <div className="nav-serviceLinkContainer">
                <NavLink
                  exact={true}
                  to="/services/pool"
                  className="nav-serviceLink"
                  activeClassName="nav-link-active"
                  isActive={isActive.bind(this, "/services/pool")}
                >
                  <span className="nav-linkText">POOL WATER DELIVERY</span>
                </NavLink>
              </div>
              <div className="nav-serviceLinkContainer">
                <NavLink
                  exact={true}
                  to="/services/construction"
                  className="nav-serviceLink"
                  activeClassName="nav-link-active"
                  isActive={isActive.bind(this, "/services/construction")}
                >
                  <span className="nav-linkText">CONCRETE CONSTRUCTION</span>
                </NavLink>
              </div>
              <div className="nav-serviceLinkContainer">
                <NavLink
                  exact={true}
                  to="/services/pumping"
                  className="nav-serviceLink"
                  activeClassName="nav-link-active"
                  isActive={isActive.bind(this, "/services/pumping")}
                >
                  <span className="nav-linkText">CONCRETE PUMPING</span>
                </NavLink>
              </div>
              <div className="nav-serviceLinkContainer">
                <NavLink
                  exact={true}
                  to="/services/excavation"
                  className="nav-serviceLink"
                  activeClassName="nav-link-active"
                  isActive={isActive.bind(this, "/services/excavation")}
                >
                  <span className="nav-linkText">EXCAVATION SERVICES</span>
                </NavLink>
              </div>
              <div className="nav-serviceLinkContainer">
                <NavLink
                  exact={true}
                  to="/services/snow"
                  className="nav-serviceLink"
                  activeClassName="nav-link-active"
                  isActive={isActive.bind(this, "/services/snow")}
                >
                  <span className="nav-linkText">SNOW REMOVAL</span>
                </NavLink>
              </div>
            </div>
          </SlideDown>
        </div>
        <div className="nav-linkContainer">
          <NavLink
            exact={true}
            to="/contact"
            className="nav-link"
            activeClassName="nav-link-active"
            isActive={isActive.bind(this, "/contact")}
          >
            <span className="nav-linkText">CONTACT US</span>
          </NavLink>
        </div>
      </div>
    );
  }
}

export default Nav;
