import React, { Component } from "react";
import { isActive } from "../Tools/LinkTools";
import { NavLink } from "react-router-dom";
import excavation from "../../Media/Graphics/excavation.png";
import construction from "../../Media/Graphics/construction.png";
import pumping from "../../Media/Graphics/pumping.png";
import pool from "../../Media/Graphics/pool.png";
import snow from "../../Media/Graphics/snow.png";
import { ServiceLinksTitle } from "../../Interfaces/Props";

import "./ServiceLinks.scss";

class ServiceLinks extends Component<ServiceLinksTitle> {
  render() {
    return (
      <div className="serviceLinks">
        <div className="serviceLinks-title">{this.props.title}</div>
        <div className="serviceLinks-container">
          <NavLink
            exact
            to="/services/construction"
            className="serviceLinks-link"
            activeClassName="serviceLinks-activeLink"
            isActive={isActive.bind(this, "/services/construction")}
          >
            <img
              className="serviceLinks-icon"
              alt="Concrete Construction Icon"
              src={construction}
            />
            <div className="serviceLinks-text">{`Concrete\nConstruction`}</div>
          </NavLink>
        </div>
        <div className="serviceLinks-container">
          <NavLink
            exact
            to="/services/pumping"
            className="serviceLinks-link"
            activeClassName="serviceLinks-activeLink"
            isActive={isActive.bind(this, "/services/pumping")}
          >
            <img
              className="serviceLinks-icon"
              alt="Concrete Pumping Icon"
              src={pumping}
            />
            <div className="serviceLinks-text">{`Concrete\nPumping`}</div>
          </NavLink>
        </div>
        <div className="serviceLinks-container">
          <NavLink
            exact
            to="/services/pool"
            className="serviceLinks-link"
            activeClassName="serviceLinks-activeLink"
            isActive={isActive.bind(this, "/services/pool")}
          >
            <img
              className="serviceLinks-icon"
              alt="Pool Water Delivery Icon"
              src={pool}
            />
            <div className="serviceLinks-text">{`Pool Water\nDelivery`}</div>
          </NavLink>
        </div>
        <div className="serviceLinks-container">
          <NavLink
            exact
            to="/services/snow"
            className="serviceLinks-link"
            activeClassName="serviceLinks-activeLink"
            isActive={isActive.bind(this, "/services/snow")}
          >
            <img
              className="serviceLinks-icon"
              alt="Snow Removal Icon"
              src={snow}
            />
            <div className="serviceLinks-text">{`Snow\nRemoval`}</div>
          </NavLink>
        </div>
        <div className="serviceLinks-container">
          <NavLink
            exact
            to="/services/excavation"
            className="serviceLinks-link"
            activeClassName="serviceLinks-activeLink"
            isActive={isActive.bind(this, "/services/excavation")}
          >
            <img
              className="serviceLinks-icon"
              alt="Excavation Icon"
              src={excavation}
            />
            <div className="serviceLinks-text">Excavation</div>
          </NavLink>
        </div>
      </div>
    );
  }
}

export default ServiceLinks;
