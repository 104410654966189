import React, { Component } from "react";
import { ImageProps } from "../../Interfaces/Props";

import "./HeaderImage.scss";

class HeaderImage extends Component<ImageProps> {
  render() {
    return (
      <div
        id="header"
        style={{
          backgroundImage: `url(${this.props.image})`
        }}
      >
        <h1>
          <div id="header-text">{this.props.title}</div>
        </h1>
      </div>
    );
  }
}

export default HeaderImage;
