import React, { Component } from "react";
import { MapURL } from "../../Interfaces/Props";

import "./Map.scss";

class Map extends Component<MapURL> {
  render() {
    return (
      <div className="map">
        <iframe
          title="Eat Right Bucks County Location Map"
          className="map-map"
          src={this.props.url}
        />
      </div>
    );
  }
}

export default Map;
