import React, { Component } from "react";
import ContactForm from "../../ContactForm/Contact";
import { ImageProps, ContactProps } from "../../../Interfaces/Props";
import services from "../../../Media/Images/services.jpg";
import HeaderImage from "../../HeaderImage/HeaderImage";
import OverviewText from "../../OverviewText/OverviewText";
import Helmet from "react-helmet";

import "./Services.scss";
import ServiceLinks from "../../ServiceLinks/ServiceLinks";

class Services extends Component<ContactProps> {
  render() {
    const imageProps: ImageProps = {
      title: "Our Services",
      image: services
    };
    return (
      <div id="services">
        <Helmet>
          <title>Our Services</title>
          <meta
            name="description"
            content="Learn more about the services Froman Excavating has to offer"
          />
        </Helmet>
        <HeaderImage {...imageProps} />
        <OverviewText
          subtitle={"We’ll Get It Right"}
          overview={[
            "No job is too big or too small for Froman Excavating. From filling a swimming pool to laying a massive foundation, our team will execute any task with care, precision, and determination.",
            `“We value and respect all of our customers equally, whether you’re buying a load of topsoil or laying a 30,000 square foot foundation.” -Terry Froman`
          ]}
        />
        <ServiceLinks title={"Learn More About Our Services"} />
        <ContactForm email={this.props.email} />
      </div>
    );
  }
}

export default Services;
